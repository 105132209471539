import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Welcom from '../views/Welcom.vue'
import Status from '../views/Status.vue'
import AccountClassify from '../views/AccountClassify.vue'
import AccountDetail from '../views/AccountDetail.vue'
import CourseList from '../views/CourseList.vue'
import Video from '../views/Video.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcom',
    component: Welcom
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/status',
    name: 'Status',
    component: Status
  },
  {
    path: '/account-classify/:bizId',
    name: 'AccountClassify',
    component: AccountClassify
  },
  {
    path: '/course-list/:id',
    name: 'CourseList',
    component: CourseList
  },
  {
    path: '/account-detail/:bizId/:id/:name',
    name: 'AccountDetail',
    component: AccountDetail
  },
  {
    path: '/video/:bizId/:courseId/:courseTitle/:infoId/:mode',
    name: 'Video',
    component: Video
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
