<template>
  <div class="page-container">
    <div class="logo-wrapper">
      <img src="../assets/img/logo.png" alt class="logo-img" />
    </div>
    <div class="login-container">
      <div class="login-title">验证码登录</div>
      <div class="login-item username-item">
        <div class="input-img-wrapper">
          <img src="../assets/img/username.png" alt class="login-icon" />
        </div>
        <div class="input-wrapper">
          <mt-field type="tel" placeholder="请输入手机号码" v-model="form.username"></mt-field>
        </div>
      </div>
      <div class="login-item">
        <div class="input-img-wrapper">
          <img src="../assets/img/password.png" alt class="login-icon" />
        </div>
        <div class="input-wrapper">
          <mt-field
            type="text"
            maxlength="6"
            placeholder="请输入验证码"
            v-model="form.password"
            :disabled="passwordEdit"
          ></mt-field>
        </div>
        <div v-if="messageBtn=='send'" class="password-btn-wrapper" @click="handlegetMessage">
          <div class="password-btn">获取验证码</div>
        </div>
        <div v-else slot="suffix" class="password-btn-wrapper">
          <div class="password-btn">({{countDown}}s)</div>
        </div>
      </div>
      <div class="login-btn" @click="login">登录</div>
    </div>
    <div>
      <!--<div id="TencentCaptcha" data-appid="2000842234" data-cbfn="callbackName"></div>-->
    </div>
  </div>
</template>

<script>
import { sendMessage, requestLogin } from "@/api/index.js";
import axios from "axios";
import { Toast } from "mint-ui";
export default {
  name: "",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      messageBtn: "send",
      countDown: 60,
      captchaData: {},
      passwordEdit: true
    };
  },
  mounted() {
    this.$nextTick(function() {});
  },
  methods: {
    captcha() {
      var _this = this;
      var captcha = new TencentCaptcha("2000842234", function(res) {
        if (res.ret == 0) {
          _this.countTime(res);
          _this.captchaData = res;
        }
      });
      captcha.show();
    },
    handlegetMessage() {
      var username = this.form.username;
      if (username == "") {
        Toast({
          message: "请输入手机号",
          position: "bottom",
          duration: 2000
        });
        return false;
      }
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(username)) {
        Toast({
          message: "请输入正确的手机号",
          position: "bottom",
          duration: 2000
        });
        return false;
      }
      this.captcha();
    },
    countTime(data) {
      this.messageBtn = "time";
      this.passwordEdit = false;
      var num = this.countDown;
      var _this = this;
      var countTimeNum = setInterval(function() {
        if (num > 0) {
          num--;
          _this.countDown = num;
        } else {
          clearInterval(countTimeNum);
          _this.messageBtn = "send";
          _this.countDown = 60;
        }
      }, 1000);
      this.startSendMessage(data);
    },
    startSendMessage(data) {
      //15615123027
      var _this = this;
      sendMessage(
        {
          appid: data.appid,
          mobile: _this.form.username,
          randstr: data.randstr,
          ret: "0",
          ticket: data.ticket
        },
        {},
        {}
      )
        .then(res => {
          if (res.code == 200) {
            Toast({
              message: "验证码已发送",
              position: "bottom",
              duration: 2000
            });
          } else {
            Toast({
              message: res.msg,
              position: "bottom",
              duration: 2000
            });
          }
          return;
        })
        .catch(err => {
          console.log(err);
        });
    },
    login() {
      var username = this.form.username;
      var password = this.form.password;
      if (username == "") {
        Toast({
          message: "请输入手机号",
          position: "bottom",
          duration: 2000
        });
        return false;
      }
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(username)) {
        Toast({
          message: "请输入正确的手机号",
          position: "bottom",
          duration: 2000
        });
        return false;
      }
      if (password == "") {
        Toast({
          message: "请输入验证码",
          position: "bottom",
          duration: 2000
        });
        return false;
      }
      var _this = this;
      requestLogin(
        {
          captcha: password,
          mobile: username,
          randstr: _this.captchaData.randstr
        },
        {},
        {}
      )
        .then(res => {
          if (res.code == 200) {
            var token = res.data.token;
            window.localStorage.setItem("token", token);
            this.$router.push("/");
          } else if (res.code == 40004) {
            this.$router.push("/status");
          } else {
            Toast({
              message: "验证码错误",
              position: "bottom",
              duration: 2000
            });
          }
          return;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.page-container {
  width: 100%;
  height: 100vh;
  /*background-image: url(../assets/img/bg.png);*/
  background: linear-gradient(180deg, #ffefc0 0%, #ffdb8c 100%);
  background-repeat: no-repeat;
  background-origin: center;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-wrapper {
  position: absolute;
  left: 5%;
  top: 41px;
}
.logo-img {
  width: 171px;
  height: 90px;
}
.login-container {
  width: 90%;
  max-width: 360px;
}
.login-title {
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #242424;
}
.login-item {
  margin-bottom: 20px;
  display: flex;
  border-radius: 23px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.input-wrapper {
  flex: 1;
}
.username-item {
  margin-top: 60px;
}
.login-icon {
  width: 16px;
  height: 17px;
}
.input-img-wrapper {
  width: 36px;
  height: 46px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.login-item >>> .mint-cell {
  width: 100% !important;
  height: 46px !important;
  padding-left: 0px !important;
  font-size: 16px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  color: #000000 !important;
}
.login-item >>> .mint-field {
  width: 100% !important;
  height: 46px !important;
  border-radius: 23px !important;
  padding-left: 0px !important;
  font-size: 16px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  color: #000000 !important;
}
.login-item >>> .mint-field-core {
  width: 100% !important;
  height: 46px !important;
  background: #fff !important;
}
.password-btn-wrapper {
  width: 116px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  top: 13px;
}
.password-btn {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4f79ed;
  border-left: solid #d0d4dd 1px;
  text-align: center;
  cursor: pointer;
}
.login-btn {
  width: 100%;
  height: 46px;
  background: linear-gradient(90deg, #4182e1 0%, #3777d6 50%, #2466cc 100%);
  border-radius: 23px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  cursor: pointer;
}
</style>
