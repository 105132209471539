<template>
    <div class="page-container">
        <div class="page-back" @click="handleBack">
            <img src="../assets/img/back.png" alt="" class="back-img">
        </div>
        <div class="page-content">
            <img src="../assets/img/slider2.png" alt="" class="slider-img">
            <div class="page-title">
                您还未购买 请先购买
            </div>
            <div class="page-btn" @click="handleBuy">立即购买</div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components:{
      
    },
    data() {
        return {
            
        }
    },
    mounted(){
        
    },
    methods: {
        handleBack(){
             this.$router.push("/login");
        },
        handleBuy(){
            window.location.href="https://zhjy-front.taxchina.com/more_companies";
        }
    }
}
</script>

<style scoped>
.page-container{
    width:100%;
    height:100vh;
    background: linear-gradient(180deg, #FFEFC0 0%, #FFDB8C 100%);
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.slider-img{
    width:253px;
    height:181px;
}
.page-title{
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242424;
    margin-top:21px;
    text-align: center;
}
.page-btn{
    width:144px;
    height:37px;
    background: linear-gradient(180deg, #72A3F5  0%, #5580F5 100%);
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0 auto;
    margin-top:79px;
    border-radius:30px;
    cursor: pointer;
}
.page-back{
    position: absolute;
    left:20px;
    top:23px;
}
.back-img{
    width:22px;
    height:22px;
    cursor: pointer;
}
</style>


