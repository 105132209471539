
let baseUrl;
//console.log(process.env.VUE_APP_CURRENTMODE);
if (process.env.VUE_APP_CURRENTMODE === 'development') {
    // baseUrl = "https://guopei-api-dev.taxchina.com";
    baseUrl = "https://guopei-api-test.taxchina.com";

}
else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    baseUrl = "https://guopei-api-test.taxchina.com";
}
else if (process.env.VUE_APP_CURRENTMODE === 'production') {
    baseUrl = "https://guopei-api.taxchina.com";
}
export default {
    systemname: '',
    baseUrl: baseUrl
};  