<template>
  <div class="page-container">
    <mt-header fixed :title="headerTitle" class="header-wrapper">
      <router-link :to="'/account-classify/' + bizId" slot="left">
        <mt-button icon="back" class="header-icon"></mt-button>
      </router-link>
    </mt-header>

    <div v-if="device=='phone'" class="page-content-container content-view">
      <div class="page-content-wrapper">
        <div class="select-date-container">
          <div class="select-date-item" @click="selectStartDate">
            {{startDate}}
            <img src="../assets/img/drop.png" alt class="date-img" />
          </div>
          <div class="select-date-flag">至</div>
          <div class="select-date-item" @click="selectEndDate">
            {{endDate}}
            <img src="../assets/img/drop.png" alt class="date-img" />
          </div>
        </div>

        <mt-loadmore
          :top-method="loadTop"
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
        >
          <div v-if="tableData.length>0">
            <div v-for="(item,key) in tableData" :key="key" class="card-container">
              <div class="card-wrapper">
                <div class="card-content">
                  <div class="card-title">{{item.title}}</div>
                  <div
                    v-if="item.liveStatus==1"
                    class="card-btn-active"
                    @click="handleDetail(item,'waiting-live')"
                  >
                    进入直播
                  </div>
                  <div v-else-if="item.liveStatus==2" class="card-btn1" 
                 >
                    回放生成中
                  </div>
                  <div
                    v-else-if="item.liveStatus==3"
                    class="card-btn-active"
                    @click="handleDetail(item,'playback')"
                  >查看直播回放</div>
                  <div v-else class="card-btn1">
                    等待直播
                  </div>
                </div>
                <div class="card-img-wrapper">
                  <img :src="item.teacherImage" alt class="auth-img" />
                  <div class="card-name">{{item.teacherName}}</div>
                </div>
              </div>
              <div class="card-time-wrapper">时间 {{translateDate(item.startTime)}}</div>
            </div>
          </div>
          <div v-else class="no-data">暂无数据</div>
        </mt-loadmore>
      </div>
    </div>

    <div v-else class="page-content-container content-view">
      <div class="page-content-wrapper">
        <div class="select-date-container">
          <div class="select-date-item-pc">
            <el-date-picker
              v-model="startDate"
              type="date"
              size="small"
              :clearable="false"
              @change="handleStartDate"
              placeholder="选择开始日期">
            </el-date-picker>
            <!-- <img src="../assets/img/drop.png" alt class="date-img-pc" /> -->
          </div>
          <div class="select-date-flag" style="margin-right:30px;">至</div>
          <div class="select-date-item-pc">
            <el-date-picker
              v-model="endDate"
              type="date"
              size="small"
              :clearable="false"
              @change="handleEndDate"
              placeholder="选择结束日期">
            </el-date-picker>
            <!-- <img src="../assets/img/drop.png" alt class="date-img-pc" /> -->
          </div>
        </div>

        <div v-if="tableData.length>0">
          <div class="card-body-container">
            <div v-for="(item,key) in tableData" :key="key" class="card-container-pc">
              <div class="card-wrapper">
                <div class="card-content">
                  <div class="card-title">{{item.title}}</div>  
                  <div
                    v-if="item.liveStatus==1"
                    class="card-btn-active"
                    @click="handleDetail(item,'waiting-live')"
                  >进入直播</div>
                  <div v-else-if="item.liveStatus==2" class="card-btn1" 
                 >
                    回放生成中
                  </div>
                  <div
                    v-else-if="item.liveStatus==3"
                    class="card-btn-active"
                    @click="handleDetail(item,'playback')"
                  >查看直播回放</div>
                  <div v-else class="card-btn1">
                    等待直播
                  </div>
                </div>
                <div class="card-img-wrapper">
                  <img :src="item.teacherImage" alt class="auth-img" />
                  <div class="card-name">{{item.teacherName}}</div>
                </div>
              </div>
              <div class="card-time-wrapper">时间 {{translateDate(item.startTime)}}</div>
            </div>
          </div>
        </div>
        <div v-else class="no-data">暂无数据</div>
      </div>
    </div>

    <mt-datetime-picker
      ref="pickerStart"
      type="date"
      v-model="startDatePicker"
      year-format="{value} 年"
      month-format="{value} 月"
      date-format="{value} 日"
      @confirm="handleStartDate"
    ></mt-datetime-picker>
    <mt-datetime-picker
      ref="pickerEnd"
      type="date"
      v-model="endDatePicker"
      year-format="{value} 年"
      month-format="{value} 月"
      date-format="{value} 日"
      @confirm="handleEndDate"
    ></mt-datetime-picker>
  </div>
</template>

<script>
import { courseList, courseDetail } from "@/api/index.js";
import { Toast, MessageBox } from "mint-ui";
export default {
  name: "",
  components: {},
  data() {
    return {
      headerTitle: "",
      startDate: "",
      endDate: "",
      startDatePicker: "",
      endDatePicker: "",
      allLoaded: false,
      tableData: [],
      token: "",
      courseId: "",
      currentPage: 1,
      totalPages: 1,
      device:'',
      bizId: 0
    };
  },
  created() {
    this.device=window.localStorage.getItem('device');

    this.bizId = this.$route.params.bizId;
  },
  mounted() {
    var _this = this;
    this.justifyToken(function() {
      _this.init();
    });
  },
  methods: {
    justifyToken(callback) {
      this.token = window.localStorage.getItem("token");
      this.courseId = this.$route.params.id;
      this.headerTitle = this.$route.params.name;
      if (!this.token || this.token == "") {
        this.$router.push("/login");
      } else {
        callback();
      }
    },
    init() {
      var videoDateStr=window.localStorage.getItem('videoDate');
      if(videoDateStr){
        var videoDate = JSON.parse(videoDateStr);
        this.startDate = videoDate.start;
        this.endDate = videoDate.end;
        this.startDatePicker = videoDate.start;
        this.endDatePicker = videoDate.end;
      }else{
        var defaultDate=this.getDefaultDate();
        this.startDate = defaultDate.start;
        this.endDate = defaultDate.end;
        var videoData = defaultDate;
        window.localStorage.setItem('videoDate',JSON.stringify(videoData));
      }
      this.getTableData();
    },
    getDefaultDate(){
        var date=new Date();
        var timestamp=date.getTime();
        var activeDay=date.getDay();
        if(activeDay==0){
            activeDay=7;
        }
        var leftTemps=24*60*60*1000*(activeDay-1);
        var lastTemps=timestamp-leftTemps;
        var lastDay=this.getCurrentDate(lastTemps);
        var rightTemps=24*60*60*1000*(7-activeDay);
        var nextTemps=timestamp+rightTemps;
        var nextDay=this.getCurrentDate(nextTemps);
        var defaultDay={
            start:lastDay,
            end:nextDay
        }
        return defaultDay;
    },
    getTableData() {
      var _this = this;
      var startTime = this.startDate + " 00:00:00";
      var endTime = this.endDate + " 23:59:59";
      var courseId = this.courseId;
      courseList(
        {
          serviceType: Number(courseId),
          startTime: startTime,
          endTime: endTime,
          page: this.currentPage,
          pageSize: 1000
        },
        {
          Authorization: _this.token
        },
        {}
      )
        .then(res => {
          if (res.code == 200) {
            var data = res.data.courseList;
            this.tableData = data;
          } else if (res.code == 50000) {
            Toast({
              message: "会话失效，请重新登陆！",
              position: "bottom",
              duration: 2000
            })
            ;
            window.localStorage.clear();
            setTimeout(function() {
              _this.$router.push("/login");
            }, 2000);
          }
          return;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCurrentDate(params) {
      var date = new Date();
      if (params && params != "") {
        date = new Date(params);
      }
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      var currentDate = `${year}-${month}-${day}`;
      return currentDate;
    },
    translateDate(params) {
      var date = new Date(params);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      var currentDate = `${year}.${month}.${day} ${hours}:${minutes}`;
      return currentDate;
    },
    selectStartDate() {
      this.startDatePicker = this.startDate;
      this.$refs.pickerStart.open();
    },
    selectEndDate() {
      this.endDatePicker = this.endDate;
      this.$refs.pickerEnd.open();
    },
    handleStartDate(val) {
      var dateVal = this.getCurrentDate(val);
      this.startDate = dateVal;
      console.log(dateVal);
      var videoData=JSON.parse(window.localStorage.getItem('videoDate'));
      videoData.start=dateVal;
      window.localStorage.setItem('videoDate',JSON.stringify(videoData));
      this.getTableData();
    },
    handleEndDate(val) {
      var dateVal = this.getCurrentDate(val);
      this.endDate = dateVal;
      console.log(dateVal);
      var videoData=JSON.parse(window.localStorage.getItem('videoDate'));
      videoData.end=dateVal;
      window.localStorage.setItem('videoDate',JSON.stringify(videoData));
      this.getTableData();
    },
    handleDetail(row,mode) {
      this.$router.push(`/video/${this.bizId}/${this.courseId}/${this.headerTitle}/${row.infoId}/${mode}`);
      /*var _this = this;
      var id = row.courseId;
      courseDetail(
        id,
        {
          courseId: id
        },
        {
          Authorization: _this.token
        },
        {}
      )
        .then(res => {
          if (res.code == 200) {
           _this.$router.push(`/video/${this.courseId}/${this.headerTitle}/${row.infoId}/${mode}`);
          } else if (res.code == 50000) {
            Toast({
              message: "会话失效，请重新登陆！",
              position: "bottom",
              duration: 2000
            });
            window.localStorage.clear();
            setTimeout(function() {
              _this.$router.push("/login");
            }, 2000);
          } else {
            Toast({
              message: res.msg,
              position: "bottom",
              duration: 2000
            });
          }
          return;
        })
        .catch(err => {
          console.log(err);
        });*/
    },
    loadTop() {
      this.$refs.loadmore.onTopLoaded();
      this.getTableData();
    },
    loadBottom() {
      this.allLoaded = true;
      this.$refs.loadmore.onBottomLoaded();
    }
  }
};
</script>

<style scoped>
body {
  background: #fcfcfc;
}
.page-container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #ffefc0 0%, #ffdb8c 100%);
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.header-wrapper {
  height: 44px;
  background: #ffefc0;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.header-icon {
  color: rgb(155, 159, 170);
  cursor: pointer;
}
.content-view {
  margin-top: 44px;
} 
.card-body-container{
  width:1200px;
  margin:0 auto;
  display: flex;
  flex-wrap: wrap;
}
.page-content-container {
  display: flex;
}
.page-content-wrapper {
  flex: 1;
  width: 100%
   /* margin-left: 15px; */
  /* margin-right: 15px;  */
}
.select-date-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 20px;
}
.select-date-item {
  width: 130px;
  height: 38px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-date-item-pc{
  width: 130px;
  height: 38px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.select-date-flag {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 38px;
  margin-left: 10px;
  margin-right: 10px;
}
.date-img {
  width: 14px;
  height: 8px;
  margin-left: 8px;
}
.date-img-pc {
  width: 14px;
  height: 8px;
  margin-left: 8px;
  display: inline-block;
  position: absolute;
  right:20px;
}
.card-container {
  width: 100%;
  height: 165px;
  display: flex;
  background-image: url("../assets/img/card.png");
  background-size: 100% 100%;
  position: relative;
  margin-top: 10px;
  /* margin-right: -10px; */
}
.card-container-pc {
  width: 33%;
  height: 165px;
  display: flex;
  background-image: url("../assets/img/card.png");
  background-size: 100% 100%;
  position: relative;
  margin-top: 10px;
}
.card-wrapper {
  flex: 1;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.card-img-wrapper {
  width: 60px;
  /* margin-left: 20px; */
}
.auth-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ededed;
  border: 1px solid #e0e0e0;
}
.card-content {
  flex:1;
  margin-left: 20px;
}
.card-title {
  width:220px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303b45;
  margin-top: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.card-btn1 {
  width: 117px;
  height: 29px;
  border-radius: 40px;
  border: 1px solid #cacaca;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cacaca;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}
.card-btn {
  width: 117px;
  height: 29px;
  border-radius: 40px;
  border: 1px solid #f69264;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f69264;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}
.card-btn:hover{
  cursor: pointer;
  background: #f69264;
  color: #fff;
}
.card-btn-active {
  width: 117px;
  height: 29px;
  border-radius: 40px;
  border: 1px solid #f69264;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f69264;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  cursor: pointer;
  transition: 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  animation: anim 2s;
}
.card-btn-active:hover{
  cursor: pointer;
  background: #f69264;
  color: #fff;
}
.card-name {
  margin-top: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #303b45;
  text-align: center;
}
.no-data {
  font-size: 13px;
  text-align: center;
  color: #c0c4cc;
}
.card-time-wrapper {
  width: 183px;
  height: 22px;
  background-image: url("../assets/img/rect.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #303b45;
  position: absolute;
  right: 20px;
  top: -5px;
}
.select-date-item-pc>>>.el-input__inner{
  border: 0px !important;
}
</style>
