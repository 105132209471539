import axios from 'axios';
class HttpRequest {
    constructor(baseUrl, timeout = 0) {
        this.baseUrl = baseUrl;
        this.timeout = timeout;
        this.queue = {};
    }
    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            timeout: this.timeout,
            headers: {
                
            }
        };
        return config;
    }
    destroy(url) {
        delete this.queue[url];
    }
    interceptors(instance, url) {
        // 请求拦截
        instance.interceptors.request.use(
            config => {
                // 添加全局的loading...
                this.queue[url] = true;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        // 响应拦截
        instance.interceptors.response.use(
            res => {
                // console.log(res);
                if (res.data.result == '401') {
                } else {
                    this.destroy(url);
                    return res.data;
                }
                // const { data, status } = res;
                // return { data, status };
            },
            error => {
                this.destroy(url);
                let errorInfo = error.response;
                /*if (!errorInfo) {
                    const {
                        request: { statusText, status },
                        config
                    } = JSON.parse(JSON.stringify(error));
                    errorInfo = {
                        statusText,
                        status,
                        request: { responseURL: config.url }
                    };
                }*/
                return Promise.reject(error);
            }
        );
    }
    request(options) {
        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url);
        return instance(options);
    }
    get(url, data,header, options = {}) {
        options.url = url;
        options.params = data;
        options.headers=header;
        options.method = 'GET';
        return this.request(options);
    }
    post(url, data, header,options = {}) {
        options.url = url;
        // data = qs.stringify(data);
        options.data = data;
        options.method = 'POST';
        options.headers=header;
        return this.request(options);
    }
}
export default HttpRequest;