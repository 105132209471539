<template>
  <div class="page-container">
    <!--<div class="page-back" @click="handleBack">
            <img src="../assets/img/back.png" alt="" class="back-img">
    </div>-->
    <div class="page-content">
      <div class="showPhone" @click="showModal"><div class="btnTop">app</div><div class="btnBottom">使用说明</div></div>
      <img src="../assets/img/slider1.png" alt class="slider-img" />
      <div class="page-title">欢迎进入中税网学习中心</div>
      <div class="page-btn" @click="bizId2Entry">高级会计师 / 中高级经济师</div>
      <div class="page-btn" @click="accountRegister">注册会计师</div>
      <div class="page-btn" @click="handleStudy">会计职称 / 税务师</div>
      <div class="page-btn" @click="economist">经济师</div>

    </div>
     <el-dialog
      :visible.sync="dialogVisible">
      <div class="phoneWrapper" v-loading="Loading">
        <div v-if="isPhone">
          <img class="imgBg" src="../assets/img/phoneBg.png" />
        <div class="phone-list" v-for="(item, index) in phoneList" :key="index">
          <div class="phone-title">{{item.title}}</div>
          <div class="phone-text">如需使用APP登录，请记住你的专属号</div>
          <div class="phone-text">账号:{{item.phoneNumber}}， 密码:{{item.password}}</div>
        </div>
        </div>
        <div class="phoneNone" v-else>
          <img class="imgBg" src="../assets/img/phoneNone.png" />
          <div>暂无权限</div>
        </div>
        
      </div>
    </el-dialog>
    <div class="logout-wrapper">
      <div class="logout" @click="handleBack">退出</div>
    </div>
  </div>
</template>

<script>
import { startStudy, serviceList ,econoMist, getPhoneList} from "@/api/index.js";
import { Loading } from "element-ui";

import { Toast, MessageBox } from "mint-ui";
export default {
  name: "",
  components: {},
  data() {
    return {
      token: "",
      device: "",
      dialogVisible: false,
      phoneList: [],
      isPhone: false,
      Loading: false
    };
  },
  mounted() {
    var _this = this;
    this.justifyToken(function() {
      _this.justifyDevice();
    });
  },
  methods: {
    // 用户退出按钮
    handleBack() {
      var _this = this;
      if (this.device == "phone") {
        MessageBox.confirm("确认要退出?").then(action => {
          window.localStorage.clear();
          _this.$router.push("/login");
        });
      } else {
        this.$confirm("确认要退出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            window.localStorage.clear();
            _this.$router.push("/login");
          })
          .catch(() => {});
      }
    },
    justifyToken(callback) {
      this.token = window.localStorage.getItem("token");
      if (!this.token || this.token == "") {
        this.$router.push("/login");
      } else {
        callback();
      }
    },

    showModal() {
      this.token = window.localStorage.getItem("token");
      this.dialogVisible = true
      this.Loading = true
      getPhoneList({}, {Authorization: this.token}).then((res) => {
        if (res.code === 200) {
          this.Loading = false
          let data = res.data
          if (data) {
              this.isPhone = true

              if (data.fhPhone) {
                this.$set(this.phoneList, 0, {title: '经济师', phoneNumber: data.fhPhone, password: 123456})
              }
              if (data.ydPhone && data.fhPhone) {
                let str = data.ydPhone.slice(-6)
                this.$set(this.phoneList, 1, {title:'会计职称 / 税务师', phoneNumber: data.ydPhone, password:str})
              } else if (data.ydPhone) {
                 let str = data.ydPhone.slice(-6)
                this.$set(this.phoneList, 0, {title:'会计职称 / 税务师', phoneNumber: data.ydPhone, password:str})

              }
          } else {
              this.isPhone = false

          }
        } else if (res.code == 50000) {
            Toast({
              message: "会话失效，请重新登陆！",
              position: "bottom",
              duration: 2000
            });
            window.localStorage.clear();
            this.Loading = false
            this.dialogVisible = false
            setTimeout(() =>{
              this.$router.push("/login");
            }, 100);
          } else {
          
            Toast({
              message: res.msg,
              position: "bottom",
              duration: 2000
            });
            window.localStorage.clear();
            this.Loading = false
            this.dialogVisible = false
            setTimeout(() =>{
              this.$router.push("/login");
            }, 100);
          }
          return;
      }).catch(err => {
          this.Loading = false
          this.dialogVisible = false
          console.log(err);
        })
    },

    // “高级会计师 / 中高级经济师” 入口
    bizId2Entry() {
      var _this = this;
      var bizId = 2;
      serviceList(
        {
          bizId: bizId
        },
        {
          Authorization: _this.token
        },
        {}
      )
        .then(res => {
          if (res.code == 200) {
            var data = res.data.serviceList;
            this.tableData = data;

            if (res.data.serviceList.length > 0) {
              //大于0做别的事情
              console.log("22222222222222222222222");
              this.$router.push("/account-classify/" + bizId);
            } else {
              console.log("111111111111111111");
              Toast({
                message: "您暂无此项目的权限，可联系业务员购买",
                position: "bottom",
                duration: 2000
              });
            }
          } else if (res.code == 50000) {
            Toast({
              message: "会话失效，请重新登陆！",
              position: "bottom",
              duration: 2000
            });
            window.localStorage.clear();
            setTimeout(function() {
              _this.$router.push("/login");
            }, 2000);
          } else {
            Toast({
              message: res.msg,
              position: "bottom",
              duration: 2000
            });
          }
          return;
        })
        .catch(err => {
          console.log(err);
        });
    },
    accountRegister() {
      // getTableData();
      // this.$router.push("/account-classify");

      // Toast({
      //   message: '会话失效，请重新登陆！',
      //   position: 'bottom',
      //   duration: 2000
      //           });

      var _this = this;
      var bizId = 1;
      serviceList(
        {
          bizId: bizId
        },
        {
          Authorization: _this.token
        },
        {}
      )
        .then(res => {
          if (res.code == 200) {
            var data = res.data.serviceList;
            this.tableData = data;

            if (res.data.serviceList.length > 0) {
              //大于0做别的事情
              console.log("22222222222222222222222");
               this.$router.push("/account-classify/" + bizId);
            } else {
              console.log("111111111111111111");
              Toast({
                message: "您暂无此项目的权限，可联系业务员购买",
                position: "bottom",
                duration: 2000
              });
            }
          } else if (res.code == 50000) {
            Toast({
              message: "会话失效，请重新登陆！",
              position: "bottom",
              duration: 2000
            });
            window.localStorage.clear();
            setTimeout(function() {
              _this.$router.push("/login");
            }, 2000);
          } else {
            Toast({
              message: res.msg,
              position: "bottom",
              duration: 2000
            });
          }
          return;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleStudy() {
      var _this = this;
      this.justifyToken(function() {
        startStudy(
          {},
          {
            Authorization: _this.token
          },
          {}
        )
          .then(res => {
            if (res.code == 200) {
              var dataStr = res.data;
              var data = JSON.parse(dataStr);
              if (_this.device == "phone") {
                window.location.href = data.h5Url;
              } else {
                window.location.href = data.pcUrl;
              }
            } else if (res.code == 40003) {
              Toast({
                message: "您暂无此项目的权限，可联系业务员购买",
                position: "bottom",
                duration: 2000 
              });
              // window.localStorage.clear();
            } else if (res.code == 50000) {
              Toast({
                message: "会话失效，请重新登陆！",
                position: "bottom",
                duration: 2000
              });
              window.localStorage.clear();
              setTimeout(function() {
                _this.$router.push("/login");
              }, 2000);
            } else {
              Toast({
                message: res.msg,
                position: "bottom",
                duration: 2000
              });
            }
            return;
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    economist() {
      var _this = this;
      this.justifyToken(function() {
        econoMist(
          {},
          {
            Authorization: _this.token
          },
          {}
        )
          .then(res => {
            if (res.code == 200) {
              var dataStr = res.data;
              var data = JSON.parse(dataStr);
              if (_this.device == "phone") {
                window.location.href = data.h5Url;
              } else {
                window.location.href = data.url;
              }
            } else if (res.code == 40003) {
              Toast({
                message: "您暂无此项目的权限，可联系业务员购买",
                position: "bottom",
                duration: 2000
              });
              // window.localStorage.clear();
            } else if (res.code == 50000) {
              Toast({
                message: "会话失效，请重新登陆！",
                position: "bottom",
                duration: 2000
              });
              window.localStorage.clear();
              setTimeout(function() {
                _this.$router.push("/login");
              }, 2000);
            } else {
              Toast({
                message: res.msg,
                position: "bottom",
                duration: 2000
              });
            }
            return;
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    justifyDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        //移动端
        this.device = "phone";
        window.localStorage.setItem("device", "phone");
      } else {
        //PC
        this.device = "pc";
        window.localStorage.setItem("device", "pc");
      }
    }
  }
};
</script>

<style scoped>
.page-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #ffefc0 0%, #ffdb8c 100%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.page-container .showPhone {
  position: absolute;
  right: 0;
  top: 12%;
  width: 120px;
  padding: 10px;
  color: #5985F5;
  background: #fff;
  border: 1px solid #5985F5;
  border-right: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}
@media screen and (min-width: 800px){
    .el-dialog__wrapper >>> .el-dialog {
      width: 50%;
    }
    .phoneWrapper{
      width: 40%;
      margin: 0 auto;
      text-align: center;
    }
    .imgBg{
      width: 80%;
      margin: 10px auto 30px auto;
    }
    .phone-title{
      text-align: left;
      color: #5985F5;
      font-size: 18px;
      margin-bottom: 12px;
    }
    .phone-text{
      text-align: left;
      color: rgba(88, 87, 87, 0.49);
      font-size: 17px;
      margin-bottom: 12px;

    }
}
@media screen and (max-width: 780px){
    .el-dialog__wrapper >>> .el-dialog {
      width: 90%;
    }
    .phoneWrapper{
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
    .imgBg{
      width: 100%;
      margin: 10px auto 30px auto;
    }
    .phone-title{
      text-align: left;
      color: #5985F5;
      font-size: 15px;
      margin-bottom: 12px;
    }
    .phone-text{
      text-align: left;
      color: rgba(88, 87, 87, 0.49);
      font-size: 14px;
      margin-bottom: 12px;

    }
    /* .phone-title{
      text-align: left;
      color: #FF5985F5;
      font-size: 18px;
      margin-bottom: 12px;
    }
    .phone-text{
      text-align: left;
      color: #FF7D7D7D;
      font-size: 17px;
      margin-bottom: 12px;

    } */
}
.page-content {
  width: 100%;
  text-align: center;
}
.slider-img {
  width: 253px;
  height: 181px;
}
.page-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242424;
  margin-top: 21px;
  text-align: center;
}
.page-btn {
  width: 83%;
  max-width: 360px;
  height: 37px;
  background: linear-gradient(180deg, #72a3f5 0%, #5580f5 100%);
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 30px;
}
.register-btn {
  margin-top: 79px;
}
.page-back {
  position: absolute;
  left: 20px;
  top: 23px;
}
.back-img {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.logout-wrapper {
  position: absolute;
  bottom: 65px;
  left: 0px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2e6fd1;
}
.logout {
  cursor: pointer;
}
</style>
