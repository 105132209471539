<template>
    <div class="page-container">
        <mt-header fixed title="汇学堂" class="header-wrapper">
            <router-link :to="backUrl" slot="left">
                <mt-button icon="back" class="header-icon"></mt-button>
            </router-link>
        </mt-header>
        <div v-if="device=='phone'" class="phone-wrapper">
            <div class="phone-video-wrapper">
                <div v-if="modeParams=='waiting-live'" class="waiting-live-wrapper"
                :style="'height:'+videoHeight">
                    <div style="text-align: center">
                        <div>距离直播开始还剩</div>
                    <div>{{countdown}}</div>
                </div>
                </div>
                <div v-else class="prism-player-phone" id="J_prismPlayer"></div>
                <div class="phone-video-info-wrapper">
                    <div class="phone-video-info-title">
                        {{videoData.title}}
                    </div>
                    <div class="phone-video-info-remarks-wrapper">
                        <div class="video-info-date">{{videoData.startTime}}</div>
                        <div class="video-info-line"></div>
                        <div class="video-info-name">主讲老师：{{videoData.teacherName}}</div>
                    </div>
                </div>
                <div class="phone-video-hxt-wrapper">
                    <img src="@/assets/img/hxt.png" alt="" class="phone-video-hxt">
                </div>
            </div>
        </div>
        <div v-else class="video-container">
            <div class="video-wrapper">
                <div v-if="modeParams=='waiting-live'" class="waiting-live-wrapper"
                :style="'height:'+videoHeight">
                    <div style="text-align: center">
                        <div>距离直播开始还剩</div>
                        <div>{{countdown}}</div>
                    </div>                 
                </div>
                <div v-else class="prism-player-phone" id="J_prismPlayer"></div>
                <div class="video-info-wrapper">
                    <div class="video-info-title">
                        {{videoData.title}}
                    </div>
                    <div class="video-info-remarks-wrapper">
                        <div class="video-info-date">{{videoData.startTime}}</div>
                        <div class="video-info-line"></div>
                        <div class="video-info-name">主讲老师：{{videoData.teacherName}}</div>
                    </div>
                </div>
                <div class="video-hxt-wrapper">
                    <img src="@/assets/img/hxt.png" alt="" class="video-hxt">
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { courseVideo } from "@/api/index.js";
export default {
    name: '',
    components:{
      
    },
    data() {
        return {
            device:'',
            token:'',
            courseId:'',
            courseTitle:'',
            infoId:'',
            videoData:{},
            myPlayer:null,
            backUrl:'',
            isLive:false,
            modeParams: '',
            videoHeight: '',
            countdown: '',
            remainingTime: 0,
            bizId: 0
        }
    },
    created() {
        this.device=window.localStorage.getItem('device');

        this.bizId = this.$route.params.bizId;
    },
    mounted(){
        window.scrollTo(0,0);
        var _this = this;
        this.justifyToken(function() {
            _this.getVideoData();
        });
    },
    methods: {
        justifyToken(callback) {
            this.token = window.localStorage.getItem("token");
            this.infoId = this.$route.params.infoId;
            this.courseId = this.$route.params.courseId;
            this.courseTitle = this.$route.params.courseTitle;
            this.modeParams=this.$route.params.mode;
            if(this.modeParams === 'live'){ // 直播
                this.isLive=true;
            }
            if(this.device=='phone'){
                this.videoHeight='215px';
            }else{
                this.videoHeight='640px';
            }
            this.backUrl = `/account-detail/${this.bizId}/${this.courseId}/${this.courseTitle}`;
            if (!this.token || this.token == "") {
                this.$router.push("/login");
            } else {
                callback();
            }
        },
        getVideoData(){
            courseVideo({
                "serviceType": Number(this.courseId),
                "infoId": Number(this.infoId),
                // "infoId": 55,
            },{
                Authorization: this.token
            },{})
            .then(res => {
                if (res.code == 200) {
                    this.videoData=res.data;
                    if(this.modeParams=='waiting-live'){
                        var remainingTime = Number(res.data.remainingTime);
                        if(remainingTime<0){
                            this.isLive = true
                            this.modeParams = 'live'
                            setTimeout(() => {
                                this.videoInit();

                            }, 50);
                            // remainingTime = remainingTime*(-1);
                        } else {
                        this.countdownTime(remainingTime);
                        }
                        console.log('remainingTime', remainingTime);
                    }else{
                        this.videoInit();
                    }
                } 
                return;
            })
            .catch(err => {
                console.log(err);
            });
        },
        videoInit(){
            var _this=this;
            if(this.modeParams !== 'live' &&  _this.videoData.vodMp4Url){
                if(_this.videoData.vodMp4Url.indexOf(".m3u8.mp4") != -1){
                    _this.videoData.vodMp4Url = _this.videoData.vodMp4Url.replace(".m3u8.mp4",".m3u8")
                }
            }
            console.log('this.modeParams', this.modeParams);
                        console.log('this.modeParams', _this.isLive);

            this.myPlayer = new Aliplayer({
            id: 'J_prismPlayer',
            width: "100%", 
            height: _this.videoHeight,
            autoplay: false,  
            cover: '',
            source : _this.modeParams === 'live' ? _this.videoData.playHlsUrl : _this.videoData.vodMp4Url,
            isLive: _this.isLive,
            "controlBarVisibility": "hover",
            "skinLayout": [
                {
                    name: "bigPlayButton", 
                    align: "cc"
                },
                /*{
                    "name": "H5Loading",
                    "align": "cc"
                },*/
                /*{
                    "name": "errorDisplay",
                    "align": "tlabs",
                    "x": 0,
                    "y": 0
                },*/
                {
                    "name": "infoDisplay"
                },
                {
                    "name": "tooltip",
                    "align": "blabs",
                    "x": 0,
                    "y": 56
                },
                {
                    "name": "controlBar",
                    "align": "blabs",
                    "x": 0,
                    "y": 0,
                    "children": [
                        {
                        "name": "progress",
                        "align": "blabs",
                        "x": 0,
                        "y": 44
                        },
                        {
                        "name": "playButton",
                        "align": "tl",
                        "x": 15,
                        "y": 12
                        },
                        {
                        "name": "timeDisplay",
                        "align": "tl",
                        "x": 10,  
                        "y": 7
                        },
                        {
                        "name": "fullScreenButton",
                        "align": "tr",
                        "x": 10,
                        "y": 12
                        },
                        /*{
                        "name": "setting",
                        "align": "tr",
                        "x": 15,
                        "y": 12
                        }*/
                    ]
                }
            ]
            },function(player){
              console.log('The player is created.')
           });
        },
        countdownTime(params){
            var _this = this;
            var timeCount=setInterval(function(){
                params--
                if(params>0){
                    console.log('params', params);
                    _this.formatDate(params);
                }else{
                    clearInterval(timeCount);
                    _this.modeParams='live';
                                    _this.isLive=true;

                    _this.getVideoData();
                }
            },1000);
        },
        formatDate(params) {
            let pt = params * 1000
            var days = parseInt(pt/(1000*60*60*24));
            var leftDay = pt%(1000*60*60*24);
            var hours = parseInt(leftDay/(1000*60*60));
            var leftHours = leftDay%(1000*60*60);
            var minutes = parseInt(leftHours/(1000*60));
            var leftMinutes = leftHours%(1000*60);
            var seconds = parseInt(leftMinutes/1000);
            this.countdown = `${days}天${hours}小时${minutes}分${seconds}秒`;
        }
    }
}
</script>

<style scoped>
.page-container {
  width: 100%;
}
.header-wrapper {
  height: 44px;
  background: #fff;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.header-icon {
  color: rgb(155, 159, 170);
  cursor: pointer;
}
.video-container{
    width:100%;
    height:640px;
    border-top:solid #DCDFE6 1px;
    margin-top:44px;
}
.video-wrapper{
    width:1200px;
    margin:0 auto;
}
.prism-player{
    height:640px;
}
.prism-player-phone{
    height:430px;
}
.video-info-title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303B45;
    margin-top:20px;
}
.phone-video-info-remarks-wrapper{
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9B9FAA;
    margin-top:20px;
    display: flex;
    align-items: center;
}
.phone-video-info-title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303B45;
    margin-top:15px;
}
.phone-video-info-wrapper{
    flex:1;
    width:94%;
    margin:0 auto;
}
.phone-video-hxt{
    width:100%;
    height:100%;
}
.video-info-remarks-wrapper{
    display: flex;
    align-items: center;
    margin-top:17px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9B9FAA;
}
.video-info-line{
    width: 1px;
    height: 10px;
    background: #9B9FAA;
    margin:0px 13px 0px 13px;
}
.video-hxt-wrapper{
    margin-top:28px;
}
.video-hxt{
    width:100%;
}
.phone-wrapper{
    width:100%;
    height:120vh;
    overflow-x: hidden;
    display:flex;
    overflow: hidden;
}
.phone-video-wrapper{
    flex:1;
    margin-top:44px;
    display:flex;
    flex-direction: column;
}
.waiting-live-wrapper{
    width:100%;
    background: rgb(53,57,56);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color:#fff;
}
</style>


