<template>
  <div class="page-container">
    <mt-header fixed title="使用汇学堂听课" class="header-wrapper">
      <router-link to="/account-classify" slot="left">
        <mt-button icon="back" class="header-icon"></mt-button>
      </router-link>
    </mt-header>
    <mt-loadmore
      v-if="device=='phone'"
      :top-method="loadTop"
      :bottom-method="loadBottom"
      :bottom-all-loaded="allLoaded"
      ref="loadmore"
    >
      <div class="page-content-container content-view">
        <div class="page-content-wrapper" @click="handleDownload">
          <!--<div v-for="(item,key) in tableData" :key="'course-'+key" class="course-item-wrapper">
              <div class="course-item-img-wrapper">
                  <img src="../assets/img/slider1.png" alt="" class="course-item-img">
              </div>
              <div class="course-content-wrapper">
                  <div class="course-content">
                      <div class="course-label">{{item.title}}</div>
                      <div class="course-btn">立即播放</div>
                  </div>
                  <div class="course-date">
                      二次学习：{{item.date}}
                  </div>
              </div>
          </div>-->
          <img src="../assets/img/list.png" alt style="width:100%;margin-top:20px;" />
        </div>
      </div>

      <div class="download-container">
        <div class="download-img-wrapper">
          <img src="../assets/img/download.png" alt class="download-img" />
        </div>
        <div class="download-content-wrapper">
          <div class="download-content">
            <div class="download-label">登录汇学堂APP，在“学习”栏目看所有课程回放</div>
            <div class="download-btn" @click="handleDownload">下载汇学堂APP</div>
          </div>
        </div>
      </div>
    </mt-loadmore>

    <div v-else class="pc-container">
      <div class="page-content-container content-view">
        <div class="page-content-wrapper" @click="handleDownload">
          <!--<div v-for="(item,key) in tableData" :key="'course-'+key" class="course-item-wrapper">
                        <div class="course-item-img-wrapper">
                            <img src="../assets /img/slider1.png" alt="" class="course-item-img">
                        </div>
                        <div class="course-content-wrapper">
                            <div class="course-content">
                                <div class="course-label">{{item.title}}</div>
                                <div class="course-btn">立即播放</div>
                            </div>
                            <div class="course-date">
                                二次学习：{{item.date}}
                            </div>
                        </div>
          </div>-->
          <img src="../assets/img/beijing.png" alt />
        </div>
      </div>

      <div class="download-container">
        <div class="download-img-wrapper" >
          <img src="../assets/img/download.png" alt class="download-img"  />
        </div>
        <div class="download-content-wrapper">
          <div class="download-content">
            <div class="download-label">登录汇学堂APP，在“学习”栏目看所有课程回放</div>
            <div class="download-btn" @click="handleDownload">下载汇学堂APP</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, MessageBox } from "mint-ui";
export default {
  name: "",
  components: {},
  data() {
    return {
      allLoaded: false,
      tableData: [],
      token: "",
      device: ""
    };
  },
  created() {
    this.device = window.localStorage.getItem("device");
  },
  mounted() {
    var _this = this;
    this.justifyToken(function() {});
  },
  methods: {
    justifyToken(callback) {
      this.token = window.localStorage.getItem("token");
      this.courseId = this.$route.params.id;
      if (!this.token || this.token == "") {
        this.$router.push("/login");
      } else {
        callback();
      }
    },
    getTableData() {},
    loadTop() {
      this.$refs.loadmore.onTopLoaded();
    },
    loadBottom() {
      this.allLoaded = true;
      this.$refs.loadmore.onBottomLoaded();
    },
    // download() {
    //   window.location.href = "http://m.taxchina.com/#/download";
    // },
    handleDownload() {
      window.location.href = "http://m.taxchina.com/#/download";
    }
  }
};
</script>

<style scoped>
.header-wrapper {
  height: 44px;
  background: #fff;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.header-icon {
  color: rgb(155, 159, 170);
  cursor: pointer;
}
.content-view {
  margin-top: 44px;
}
.page-content-container {
  display: flex;
}
.page-content-wrapper {
  flex: 1;
  margin-left: 50px;
  margin-right: 15px;
}
.pc-container {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}
.course-item-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-top: 20px;
  border-bottom: solid #eee 1px;
}
.course-item-img-wrapper {
  width: 155px;
}
.course-item-img {
  width: 140px;
  height: 80px;
  border-radius: 10px;
}
.course-content-wrapper {
  flex: 1;
}
.course-content {
  display: flex;
  justify-content: space-between;
}
.course-label {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.course-btn {
  color: rgb(33, 188, 188);
  width: 80px;
  display: flex;
  justify-content: flex-end;
}
.course-date {
  margin-top: 20px;
  font-size: 14px;
  color: #909399;
}
.download-container {
  width: 100%;
  background: #fff;
  padding: 40px 0px 70px 0px;
  display: flex;
}
.download-img {
  width: 134px;
  height: 129px;
}
.download-img-wrapper {
  flex: 2;
  text-align: center;
}
.download-content-wrapper {
  flex: 3;
}
.download-content {
  width: 180px;
  margin: 0 auto;
}
.download-label {
  font-weight: 600;
}
.download-btn {
  width: 158px;
  height: 40px;
  background: linear-gradient(90deg, #5580f5 0%, #72a3f5 100%);
  border-radius: 22px;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
</style>


