<template>
  <div class="page-container">
    <mt-header fixed :title="headerTitle" class="header-wrapper">
      <router-link to="/" slot="left">
        <mt-button icon="back" class="header-icon"></mt-button>
      </router-link>
    </mt-header>

    <div :class="device=='pc'?'pc-container':''">
      <div class="page-title-container content-view">
      <div class="page-title-wrapper">直播课</div>
      </div>
      <div class="page-content-container">
        <div class="page-content-wrapper">
          <div
            v-for="(item,index) in tableData"
            :key="'live-'+index"
            :class="device=='pc'?'page-btn-wrapper-pc':((index+1)%3==0?'page-btn-wrapper btn-last':'page-btn-wrapper')"
            @click="viewAccountDetail(item)"
          >
            <div class="page-btn">{{item.serviceName}}</div>
          </div>
        </div>
      </div>

      <div class="page-title-container">
        <div class="page-title-wrapper">直播回放集合</div>
      </div>
      <!-- <div class="page-title-container">
        <div class="page-brief-wrapper" @click="viewCourseList">
          使用汇学堂APP听回放课程 效果更佳
          <img src="../assets/img/next.png" alt class="next-img" />
        </div>
      </div>
      <div class="page-content-container">
        <div class="page-content-wrapper">
          <div
            v-for="(item,index) in tableData"
            :key="'live-'+index"
            :class="device=='pc'?'page-btn-wrapper-pc':((index+1)%3==0?'page-btn-wrapper btn-last':'page-btn-wrapper')"
            @click="viewCourseList(item)"
          >
            <div class="page-btn">{{item.serviceName}}</div>
          </div>
        </div>
      </div> -->

      <div class="download-container">
        <div class="download-img-wrapper" >
          <img src="../assets/img/download.png" alt class="download-img"  />
        </div>
        <div class="download-content-wrapper">
          <div class="download-content">
            <div class="download-label">登录汇学堂APP，在“学习”栏目看所有课程回放</div>
            <div class="download-btn" @click="handleDownload">下载汇学堂APP</div>
          </div>
        </div>
      </div>

      <!-- <div class="page-title-container">
        <div class="page-title-wrapper">题库</div>
      </div>
      <div @click="getCourseBank">
          <img src="../assets/img/question.png" alt class="question-bank" />
      </div> -->
      <div class="page-content-container">
        <div class="page-content-wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { serviceList, courseBank } from "@/api/index.js";
import { Header, Toast, MessageBox } from "mint-ui";
export default {
  name: "",
  components: {},
  data() {
    return {
      tableData: [],
      token: "",
      device:'',
      bizId: 0,
      headerTitle: ''
    };
  },
  created() {
    this.device=window.localStorage.getItem('device');

    this.bizId = this.$route.params.bizId;
    if (this.bizId == 1) {
      this.headerTitle = '注册会计师';
    } else if (this.bizId == 2) {
      this.headerTitle = '高级会计师/中高级经济师';
    }
  },
  mounted() {
    var _this=this;
    this.justifyToken(function(){
      _this.getTableData();
    });
  },
  methods: {
    justifyToken(callback){
        this.token=window.localStorage.getItem('token');
        if(!this.token || this.token==''){
            this.$router.push("/login");
        }else{
            callback();
        }
    },
    getTableData() {
      var _this = this;
      serviceList(
        {
          bizId: this.bizId
        },
        {
          Authorization: _this.token
        },
        {}
      )
        .then(res => {
          if (res.code == 200) {
            
            var data = res.data.serviceList;
            this.tableData = data;

            if(res.data.serviceList.length>0){
                 //大于0做别的事情
                //  console.log("22222222222222222222222")
            }else{
              // console.log("111111111111111111")
              // Toast({
              //   message: '您暂无此项目的权限，可联系业务员购买',
              //   position: "bottom",
              //   duration: 2000
              // });
              alert("您暂无此项目的权限，可联系业务员购买")
            }

          }else if(res.code == 50000){
              Toast({
                message: '会话失效，请重新登陆！',
                position: 'bottom',
                duration: 2000
                    });
                window.localStorage.clear();
                setTimeout(function(){
                    _this.$router.push("/login");
                },2000);
            }
            else {
            Toast({
              message: res.msg,
              position: "bottom",
              duration: 2000
            });
          }
          return;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 题库跳转链接
    // getCourseBank() {
    //   var _this = this;
    //   courseBank(
    //     {
    //       token: _this.token
    //     },
    //     {
    //       Authorization: _this.token
    //     },
    //     {}
    //   )
    //     .then(res => {
    //       if (res.code == 200) {
    //           var url=res.data.url;
    //           window.location.href=url;
    //       }else if(res.code == 50000){
    //           Toast({
    //             message: '会话失效，请重新登陆！',
    //             position: 'bottom',
    //             duration: 2000
    //                     });
    //             window.localStorage.clear();
    //             setTimeout(function(){
    //                 _this.$router.push("/login");
    //             },2000);
    //         }
    //       else {
    //         Toast({
    //           message: res.msg,
    //           position: "bottom",
    //           duration: 2000,
    //         });
    //         // _this.$message.error(res.msg);
    //       }
    //       return;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    viewCourseList(row) {
      this.$router.push(`/course-list/${row.serviceType}`);
    },
    viewAccountDetail(row) {
      this.$router.push(`/account-detail/${this.bizId}/${row.serviceType}/${row.serviceName}`);
    },
    handleDownload() {
      window.location.href = "http://m.taxchina.com/#/download";
    }
  }
};
</script>

<style scoped>
.page-container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #ffefc0 0%, #ffdb8c 100%);
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.header-wrapper {
  height: 44px;
  background: #ffefc0;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #000000;
}
.header-icon {
  color: rgb(155, 159, 170);
  cursor:pointer;
}
.content-view {
  margin-top: 44px;
}
.page-title-container {
  display: flex;
}
.pc-container{
  width:1000px;
  margin:0 auto;
  margin-top:20px;
}
.page-title-wrapper {
  flex: 1;
  margin-left: 15px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  font-weight: 600;
  margin-top: 15px;
}
.page-brief-wrapper {
  flex: 1;
  margin-left: 15px;
  margin-top: 5px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9faa;
  display: flex;
  cursor:pointer;
}
.page-content-container {
  display: flex;
}
.page-content-wrapper {
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
/* .page-btn-wrapper {
  width: 30%;
  max-width:115px;
  margin-right: 5%;
  display: flex;
  align-items: center;
} */
/* .page-btn-wrapper-pc{
  width:115px;
  margin-right: 5%;
  display: flex;
  align-items: center;
} */
.page-btn-wrapper {
  width: 100%;
  max-width:1000px;
  /* margin-right: 5%; */
  display: flex;
  align-items: center;
}
.page-btn-wrapper-pc{
  width:30%;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  align-items: center;
}
.page-btn {
  flex: 1;
  height: 38px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #f0f0f3;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 38px;
  margin-bottom: 17px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  transition: 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  animation: anim 2s;
  padding-left:5px;
}
.page-btn:hover{
  cursor: pointer;
  background:#409EFF;
  color:#fff;
  border:solid #409EFF 1px;
}
.btn-last {
  /* margin-right: 0px; */
}
.next-img {
  width: 7px;
  height: 12px;
  display: inline-block;
  position: relative;
  left: 10px;
  top: 3px;
}
.question-bank {
  width: 300px;
  padding: 15px
}

.download-container {
  width: 100%;
  background: #fff;
  padding: 40px 0px 70px 0px;
  display: flex;
  margin-top: 10px;
}
.download-img {
  width: 134px;
  height: 129px;
}
.download-img-wrapper {
  flex: 2;
  text-align: center;
}
.download-content-wrapper {
  flex: 3;
}
.download-content {
  width: 180px;
  margin: 0 auto;
}
.download-label {
  font-weight: 600;
}
.download-btn {
  width: 158px;
  height: 40px;
  background: linear-gradient(90deg, #5580f5 0%, #72a3f5 100%);
  border-radius: 22px;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
</style>

