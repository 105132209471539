
import axios from '@/utils/request';

export const sendMessage = (data, headers, options) => {
	return axios.post('/api/bus/captcha/sendsms', data, headers, options);
}
export const requestLogin = (data, headers, options) => {
	return axios.post('/api/bus/user/login', data, headers, options);
}
export const startStudy = (data, headers, options) => {
	return axios.get('/api/bus/user/login3rd', data, headers, options);
}
export const econoMist = (data, headers, options) => {
	return axios.get('api/bus/user/getFhPlatformLoginUrl', data, headers, options);
}

//服务列表
export const serviceList = (data, headers, options) => {
	return axios.post('/api/bus/user/service/getUserServiceList', data, headers, options);
}
//题库
export const courseBank = (data, headers, options) => {
	return axios.get('/api/bus/user/service/getQuestionLibUrl', data, headers, options);
}
//获取用户直播课程列表
export const  courseList= (data, headers, options) => {
	return axios.post('/api/bus/user/service/getCourseList', data, headers, options);
}
//获取直播地址
export const  courseDetail= (id, data, headers, options) => {
	return axios.get('/api/bus/user/service/getLiveUrl/'+id, data, headers, options);
}
//获取直播地址
export const  courseVideo= (data, headers, options) => {
	return axios.post('/api/bus/user/service/getLive', data, headers, options);
}
//获取直播地址
export const  getPhoneList= (data, headers) => {
	return axios.get('/api/bus/user/service/getHome',data, headers);
}
